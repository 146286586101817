/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-01-30",
    versionChannel: "nightly",
    buildDate: "2023-01-30T00:19:04.244Z",
    commitHash: "15c7b6520b525a67f5224855ec7e49eb0604aa07",
};
